<template>
  <div>
    <v-select
      v-model="selected"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      multiple
      :label="selectionLabel"
      :disabled="readonly"
      :placeholder="placeholder"
      :options="options"
      :loading="loading"
      :reduce="(item) => item[selectionKey]"
      class="n-async-multi-select"
    >
      <template #no-options>
        {{ $t("selection.noOption") }}
      </template>
      <template #spinner="{ loading }">
        <b-spinner
          v-if="loading"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { BSpinner } from "bootstrap-vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    readonly: {},
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectionKey: {
      type: String,
      default: "value",
    },
    selectionLabel: {
      type: String,
      default: "label",
    },
    initOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    values: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      selected: [],
      loading: false,
      loadedInitOptions: false,
    };
  },
  mounted() {
    if (this.values.length > 0) {
      this.selected = this.values;
    }
  },
  watch: {
    selected: function (value) {
      this.$emit("input", value);
    },
    initOptions: function (value) {
      if (!this.loadedInitOptions) {
        this.selected = this.initOptions;
        this.loadedInitOptions = true;
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>