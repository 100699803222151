var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"dragDropBox",staticClass:"image-uploader flex-center-vertically text-center",staticStyle:{"min-height":"150px","height":"100% !important","border":"1px dashed"},style:({
      borderColor: _vm.activeColor,
      cursor: _vm.readonly ? '' : 'pointer',
      backgroundColor: _vm.readonly ? _vm.secondary : '',
      width: _vm.fullWidth ? '' : (_vm.pw + "px"),
    }),on:{"click":_vm.chooseImage,"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[(!_vm.value && !_vm.image)?_c('div',[_c('feather-icon',{style:({
          color: _vm.readonly ? '#ffffff' : _vm.activeColor,
        }),attrs:{"icon":"ImageIcon","size":"30"}}),_c('div',{staticClass:"mt-2",style:({
          color: _vm.readonly ? '#ffffff' : _vm.activeColor,
        })},[_vm._v(" "+_vm._s(_vm.$t('general.clickOrDragDropToUpload'))+" ")])],1):_vm._e(),(_vm.value || _vm.image)?_c('div',[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.value ? _vm.value : _vm.image,"alt":""}})]):_vm._e()]),_c('input',{ref:"fileInput",staticClass:"w-px h-px opacity-0 overflow-hidden absolute",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":"","name":"fields[assetsFieldHandle][]","id":"assetsFieldHandle","accept":".jpg,.jpeg,.png"},on:{"change":_vm.onChange}})])}
var staticRenderFns = []

export { render, staticRenderFns }