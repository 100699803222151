<template>
  <b-input-group
    class="input-group-merge"
    :class="errors.length > 0 ? 'is-invalid' : null"
  >
    <b-form-input
      :state="errors.length > 0 ? false : null"
      class="form-control-merge"
      :type="fieldType"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="input"
      @blur="blur"
    />
  </b-input-group>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    placeholder: {},
    value: {},
    name: {},
    disabled: {},
    errors: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      fieldType: 'time',
      text: '',
    }
  },

  methods: {
    input(ev) {
      this.text = ev
      this.$emit('input', this.text)
    },
    blur() {
      this.$emit('blur')
    },
  },
}
</script>

<style scoped></style>
